import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section'

const navItems = [
  {
    title: "Nosotros",
    hook: "about-us"
  },
  {
    title: "Servicios",
    hook: "services"
  },
  {
    title: "Contacto",
    hook: "contact"
  }
]
const IndexPage = () => (
  <Layout navItems={navItems}>
    <SEO title="Home" />
    <Section title="about-us">
        <p>La Clínica Dental fue fundada por la doctora Maria del Carmen Miralles Muñoz y abrió sus puertas el 01 de Julio de 1994, con el entusiasmo que puede tener una joven dentista que desea formar su propia empresa y con una clara visión: conseguir que la clínica se convirtiera en un miembro integrado en cada familia, logrando ser la clínica dental de referencia del barrio y de las familias que en el viven.</p>
    </Section>
    <Section title="services">
      <h3>Nuestros servicios</h3>
      <p>- Cirugía oral y maxilofacial. 
        <br/>- Endodoncia. 
        <br/>- Estética dental. 
        <br/>- Implantes Dentales. 
        <br/>- Odontopediatría. 
        <br/>- Ortodoncia y ATM. 
        <br/>- Periodoncia. 
        <br/>- Regeneración ósea. 
        <br/>- Rehabilitación oral.</p>
    </Section>
    <Section title="contact">
    <div id="info">
      <span className="overline">Teléfono:</span><br/>
      <a href="phone:965447950">965 447 950</a><br/>
      <span className="overline">Email:</span><br/>
      <a href="mailto:dentalmiralles@gmail.com">dentalmiralles@gmail.com</a><br/>
      <span className="overline">Whatsapp:</span><br/>
      <a href="https://wa.me/34667548543">667 548 543</a><br/>
      <span className="overline">Dirección:</span><br/>
      <a href="https://goo.gl/maps/MGHkU47trndwyyrq5">Calle Cristóbal Sanz 31, Bajo <br/>03201 Elche, Alicante</a><br/>
      
  </div>
    </Section>
    
  </Layout>
)

export default IndexPage
