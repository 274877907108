import React from 'react'
import styled from 'styled-components'
import {theme} from '../theme/theme'
import MEDIA from '../theme/mediaTemplates'

const SectionContainer = styled.div`
    min-height: 100vh;
    position: relative;
    .container{
        position: absolute;
        bottom: ${theme.gridGutterDesktop};
        left: ${theme.gridGutterDesktop};
        p{
            margin:0px;
            font-size: 26px;
            line-height: 1.3;
            padding: 0px;
        }
    }
    ${MEDIA.PHONE`
        position: relative;
        padding: 64px ${theme.gridGutterMobile};
        min-height: 50vh;

        .container {
            position: static ;

        }
    `}
    ${MEDIA.MIN_GIANT`
        .container{
            p{
                font-size: 42px;
            }
        }

    `}
`
const Section = (props) => (
    <SectionContainer id={props.title}>
        <div className="container">
            {props.children}
        </div>
    </SectionContainer>
)

export default Section